// import React, { useState } from "react";
// import styled from 'styled-components';
// import { ethers } from "ethers";

// let ABI = [
//     {
//         "inputs": [
//             {
//                 "internalType": "string",
//                 "name": "_name",
//                 "type": "string"
//             },
//             {
//                 "internalType": "string",
//                 "name": "_symbol",
//                 "type": "string"
//             },
//             {
//                 "internalType": "string",
//                 "name": "_initBaseURI",
//                 "type": "string"
//             }
//         ],
//         "stateMutability": "nonpayable",
//         "type": "constructor"
//     },
//     {
//         "anonymous": false,
//         "inputs": [
//             {
//                 "indexed": true,
//                 "internalType": "address",
//                 "name": "owner",
//                 "type": "address"
//             },
//             {
//                 "indexed": true,
//                 "internalType": "address",
//                 "name": "approved",
//                 "type": "address"
//             },
//             {
//                 "indexed": true,
//                 "internalType": "uint256",
//                 "name": "tokenId",
//                 "type": "uint256"
//             }
//         ],
//         "name": "Approval",
//         "type": "event"
//     },
//     {
//         "anonymous": false,
//         "inputs": [
//             {
//                 "indexed": true,
//                 "internalType": "address",
//                 "name": "owner",
//                 "type": "address"
//             },
//             {
//                 "indexed": true,
//                 "internalType": "address",
//                 "name": "operator",
//                 "type": "address"
//             },
//             {
//                 "indexed": false,
//                 "internalType": "bool",
//                 "name": "approved",
//                 "type": "bool"
//             }
//         ],
//         "name": "ApprovalForAll",
//         "type": "event"
//     },
//     {
//         "anonymous": false,
//         "inputs": [
//             {
//                 "indexed": true,
//                 "internalType": "address",
//                 "name": "previousOwner",
//                 "type": "address"
//             },
//             {
//                 "indexed": true,
//                 "internalType": "address",
//                 "name": "newOwner",
//                 "type": "address"
//             }
//         ],
//         "name": "OwnershipTransferred",
//         "type": "event"
//     },
//     {
//         "anonymous": false,
//         "inputs": [
//             {
//                 "indexed": true,
//                 "internalType": "address",
//                 "name": "from",
//                 "type": "address"
//             },
//             {
//                 "indexed": true,
//                 "internalType": "address",
//                 "name": "to",
//                 "type": "address"
//             },
//             {
//                 "indexed": true,
//                 "internalType": "uint256",
//                 "name": "tokenId",
//                 "type": "uint256"
//             }
//         ],
//         "name": "Transfer",
//         "type": "event"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "to",
//                 "type": "address"
//             },
//             {
//                 "internalType": "uint256",
//                 "name": "tokenId",
//                 "type": "uint256"
//             }
//         ],
//         "name": "approve",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "owner",
//                 "type": "address"
//             }
//         ],
//         "name": "balanceOf",
//         "outputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "",
//                 "type": "uint256"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "baseExtension",
//         "outputs": [
//             {
//                 "internalType": "string",
//                 "name": "",
//                 "type": "string"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "cost",
//         "outputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "",
//                 "type": "uint256"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "tokenId",
//                 "type": "uint256"
//             }
//         ],
//         "name": "getApproved",
//         "outputs": [
//             {
//                 "internalType": "address",
//                 "name": "",
//                 "type": "address"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "",
//                 "type": "address"
//             }
//         ],
//         "name": "hasMinted",
//         "outputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "",
//                 "type": "uint256"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "owner",
//                 "type": "address"
//             },
//             {
//                 "internalType": "address",
//                 "name": "operator",
//                 "type": "address"
//             }
//         ],
//         "name": "isApprovedForAll",
//         "outputs": [
//             {
//                 "internalType": "bool",
//                 "name": "",
//                 "type": "bool"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "maxMintAmount",
//         "outputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "",
//                 "type": "uint256"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "maxSupply",
//         "outputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "",
//                 "type": "uint256"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "_mintAmount",
//                 "type": "uint256"
//             }
//         ],
//         "name": "mint",
//         "outputs": [],
//         "stateMutability": "payable",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "name",
//         "outputs": [
//             {
//                 "internalType": "string",
//                 "name": "",
//                 "type": "string"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "owner",
//         "outputs": [
//             {
//                 "internalType": "address",
//                 "name": "",
//                 "type": "address"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "tokenId",
//                 "type": "uint256"
//             }
//         ],
//         "name": "ownerOf",
//         "outputs": [
//             {
//                 "internalType": "address",
//                 "name": "",
//                 "type": "address"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "bool",
//                 "name": "_state",
//                 "type": "bool"
//             }
//         ],
//         "name": "pause",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "paused",
//         "outputs": [
//             {
//                 "internalType": "bool",
//                 "name": "",
//                 "type": "bool"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "renounceOwnership",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "from",
//                 "type": "address"
//             },
//             {
//                 "internalType": "address",
//                 "name": "to",
//                 "type": "address"
//             },
//             {
//                 "internalType": "uint256",
//                 "name": "tokenId",
//                 "type": "uint256"
//             }
//         ],
//         "name": "safeTransferFrom",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "from",
//                 "type": "address"
//             },
//             {
//                 "internalType": "address",
//                 "name": "to",
//                 "type": "address"
//             },
//             {
//                 "internalType": "uint256",
//                 "name": "tokenId",
//                 "type": "uint256"
//             },
//             {
//                 "internalType": "bytes",
//                 "name": "_data",
//                 "type": "bytes"
//             }
//         ],
//         "name": "safeTransferFrom",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "operator",
//                 "type": "address"
//             },
//             {
//                 "internalType": "bool",
//                 "name": "approved",
//                 "type": "bool"
//             }
//         ],
//         "name": "setApprovalForAll",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "string",
//                 "name": "_newBaseExtension",
//                 "type": "string"
//             }
//         ],
//         "name": "setBaseExtension",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "string",
//                 "name": "_newBaseURI",
//                 "type": "string"
//             }
//         ],
//         "name": "setBaseURI",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "_newCost",
//                 "type": "uint256"
//             }
//         ],
//         "name": "setCost",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "_newmaxMintAmount",
//                 "type": "uint256"
//             }
//         ],
//         "name": "setmaxMintAmount",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "bytes4",
//                 "name": "interfaceId",
//                 "type": "bytes4"
//             }
//         ],
//         "name": "supportsInterface",
//         "outputs": [
//             {
//                 "internalType": "bool",
//                 "name": "",
//                 "type": "bool"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "symbol",
//         "outputs": [
//             {
//                 "internalType": "string",
//                 "name": "",
//                 "type": "string"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "index",
//                 "type": "uint256"
//             }
//         ],
//         "name": "tokenByIndex",
//         "outputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "",
//                 "type": "uint256"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "owner",
//                 "type": "address"
//             },
//             {
//                 "internalType": "uint256",
//                 "name": "index",
//                 "type": "uint256"
//             }
//         ],
//         "name": "tokenOfOwnerByIndex",
//         "outputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "",
//                 "type": "uint256"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "tokenId",
//                 "type": "uint256"
//             }
//         ],
//         "name": "tokenURI",
//         "outputs": [
//             {
//                 "internalType": "string",
//                 "name": "",
//                 "type": "string"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "totalSupply",
//         "outputs": [
//             {
//                 "internalType": "uint256",
//                 "name": "",
//                 "type": "uint256"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "from",
//                 "type": "address"
//             },
//             {
//                 "internalType": "address",
//                 "name": "to",
//                 "type": "address"
//             },
//             {
//                 "internalType": "uint256",
//                 "name": "tokenId",
//                 "type": "uint256"
//             }
//         ],
//         "name": "transferFrom",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "newOwner",
//                 "type": "address"
//             }
//         ],
//         "name": "transferOwnership",
//         "outputs": [],
//         "stateMutability": "nonpayable",
//         "type": "function"
//     },
//     {
//         "inputs": [
//             {
//                 "internalType": "address",
//                 "name": "_owner",
//                 "type": "address"
//             }
//         ],
//         "name": "walletOfOwner",
//         "outputs": [
//             {
//                 "internalType": "uint256[]",
//                 "name": "",
//                 "type": "uint256[]"
//             }
//         ],
//         "stateMutability": "view",
//         "type": "function"
//     },
//     {
//         "inputs": [],
//         "name": "withdraw",
//         "outputs": [],
//         "stateMutability": "payable",
//         "type": "function"
//     }
// ];

// const Section = styled.section`

// `;

// const Button = styled.button`

// `;
// const Input = styled.input`
//     border: solid 2px ${props => props.theme.text};
//     background-color: #FFF;
//     color: ${props => props.theme.text};
//     font-size: ${props => props.theme.fontlg};
//     border-radius: 25px;
//     padding: 1rem 2rem;
//     margin: 15px 15px 0px 0px;
//     font-family: 'Patrick Hand', sans-serif;
//     transition: all 0.2s ease;

//     &:hover{
//         transform: scale(1.1);
//     }
// `;


// const Btn2 = styled.button`
//     border: solid 2px ${props => props.theme.text};
//     background-color: #fce08f;
//     color: ${props => props.theme.text};
//     font-size: ${props => props.theme.fontlg};
//     border-radius: 50px;
//     padding: 1rem 2rem;
//     margin: 15px 15px 0px 0px;
//     cursor: pointer;
//     font-family: 'Patrick Hand', sans-serif;

//     transition: all 0.2s ease;

//     &:hover{
//         transform: scale(1.1);
//     }
// `;


// const Mint = () => {
//     const [connected, setConnected] = useState(false);
//     const [mintAmount, setMintAmount] = useState(1);
//     const [alreadyMinted, setAlreadyMinted] = useState(0);
//     const [totalSupply, setTotalSupply] = useState(0);
//     const [contract, setContract] = useState(null);
//     const [account, setAccount] = useState(null);
//     const [error, setError] = useState(null);

//     const mint = async () => {
//         try {
//             let tx = await contract.mint(mintAmount, { value: ethers.utils.parseEther((0.000086 * mintAmount).toString()) });
//             await tx.wait();
//         } catch (e) {
//             if (e.data) {
//                 setError(e.data.message)
//             } else {
//                 setError(e.message)
//             }
//         }
//     }

//     const connect = async () => {
//         setError('');
//         const accounts = await window.ethereum.request({
//             method: "eth_requestAccounts",
//         });
//         setAccount(accounts[0]);
//         const networkId = await window.ethereum.request({
//             method: "net_version",
//         });
//         if (networkId.toString() === '168587773') {
//             let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
//             let tmpContract = new ethers.Contract('0xdcb5bE2582A7B10ECF07B5A56c1bED524B7d90d0', ABI, provider.getSigner());
//             setContract(tmpContract);
//             const userAddress = accounts[0];
//             const mintedCount = (await tmpContract.hasMinted(userAddress)).toNumber();
//             setAlreadyMinted(mintedCount);
//             //setAlreadyMinted((await tmpContract.hasMinted(accounts[0])).toNumber());
//             setTotalSupply((await tmpContract.totalSupply()).toNumber());
//             setConnected(true);

//             window.ethereum.on("accountsChanged", () => {
//                 window.location.reload();
//             });
//             window.ethereum.on("chainChanged", () => {
//                 window.location.reload();
//             });
//         } else {
//             setError('Please connect to Blast network.')
//         }
//     };

//     const add = async () => {
//         setMintAmount(Math.min(mintAmount + 1, 10 - (alreadyMinted)));
//     }

//     const sub = async () => {
//         setMintAmount(Math.max(mintAmount - 1, 1));
//     }

//     return (
//         <Section>
//             {connected !== false ? (
//                 <div style={{ textAlign: "center" }}>
//                     <div>
//                         Connected with {account}
//                     </div>
//                     <div>
//                         Progress: {totalSupply} / 10000
//                     </div>
//                     <div>
//                         Nuggets Minted: {alreadyMinted} / 10
//                     </div>
//                     {alreadyMinted < 10 ? (<>
//                         <div>
//                             <Btn2 onClick={sub}>-</Btn2>
//                             <Input value={mintAmount} readonly></Input>
//                             <Btn2 onClick={add}>+</Btn2>
//                         </div>
//                         <div>
//                             <Btn2 onClick={mint}>Mint!</Btn2>
//                         </div>
//                     </>) : (<>
//                         <div>You received 10 NFTs, yay!</div>
//                     </>)}
//                 </div>
//             ) : (
//                 <div>
//                     <Btn2 onClick={connect}>Connect Metamask</Btn2>
//                 </div>
//             )}
//             {error !== null ? (<div className={'error'} style={{ textAlign: "center" }}>{error}</div>) : (<></>)}
//             <br />
//             <br />
//         </Section>
//     );
// };

// export default Mint
import React, { useState } from "react";
import styled from 'styled-components';
import { ethers } from "ethers";

let ABI = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_name",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_symbol",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "_initBaseURI",
                "type": "string"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "approved",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "ApprovalForAll",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "baseExtension",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "cost",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getApproved",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "hasMinted",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            }
        ],
        "name": "isApprovedForAll",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxMintAmount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "maxSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_mintAmount",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bool",
                "name": "_state",
                "type": "bool"
            }
        ],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "_data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "operator",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "approved",
                "type": "bool"
            }
        ],
        "name": "setApprovalForAll",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_newBaseExtension",
                "type": "string"
            }
        ],
        "name": "setBaseExtension",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "_newBaseURI",
                "type": "string"
            }
        ],
        "name": "setBaseURI",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newCost",
                "type": "uint256"
            }
        ],
        "name": "setCost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_newmaxMintAmount",
                "type": "uint256"
            }
        ],
        "name": "setmaxMintAmount",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "tokenOfOwnerByIndex",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "walletOfOwner",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }
];

const Section = styled.section`
    
`;

const Button = styled.button`

`;
const Input = styled.input`
    border: solid 2px ${props => props.theme.text};
    background-color: #FFF;
    color: ${props => props.theme.text};
    font-size: ${props => props.theme.fontlg};
    border-radius: 25px;
    padding: 1rem 2rem;
    margin: 15px 15px 0px 0px;
    font-family: 'Patrick Hand', sans-serif;
    transition: all 0.2s ease;

    &:hover{
        transform: scale(1.1);
    }
`;


const Btn2 = styled.button`
    border: solid 2px ${props => props.theme.text};
    background-color: #fce08f;
    color: ${props => props.theme.text};
    font-size: ${props => props.theme.fontlg};
    border-radius: 50px;
    padding: 1rem 2rem;
    margin: 15px 15px 0px 0px;
    cursor: pointer;
    font-family: 'Patrick Hand', sans-serif;

    transition: all 0.2s ease;

    &:hover{
        transform: scale(1.1);
    }
`;


const Mint = () => {
    const [connected, setConnected] = useState(false);
    const [mintAmount, setMintAmount] = useState(1);
    const [alreadyMinted, setAlreadyMinted] = useState(0);
    const [totalSupply, setTotalSupply] = useState(0);
    const [contract, setContract] = useState(null);
    const [account, setAccount] = useState(null);
    const [error, setError] = useState(null);
    // Calculate the total cost in wei directly to avoid fractional issues
    const costPerNftInWei = ethers.utils.parseUnits('0.000086', 'ether'); // This converts the ether value to wei
    const totalCostInWei = costPerNftInWei.mul(mintAmount); // Use BigNumber multiplication to calculate total cost


    const mint = async () => {
        try {
            // let tx = await contract.mint(mintAmount, { value: ethers.utils.parseEther((0.000086 * mintAmount).toString()) });
            // await tx.wait();
            let tx = await contract.mint(mintAmount, { value: totalCostInWei });
            await tx.wait();

            // Fetch the updated minted count after the transaction is confirmed
            const updatedMintedCount = (await contract.hasMinted(account)).toNumber();
            setAlreadyMinted(updatedMintedCount); // Update the state

            // Optionally, you can also update the total supply if needed
            const updatedTotalSupply = (await contract.totalSupply()).toNumber();
            setTotalSupply(updatedTotalSupply);

        } catch (e) {
            if (e.data) {
                setError(e.data.message)
            } else {
                setError(e.message)
            }
        }
    }

    const connect = async () => {
        setError('');
        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
        const networkId = await window.ethereum.request({
            method: "net_version",
        });
        if (networkId.toString() === '168587773') {
            let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            let tmpContract = new ethers.Contract('0xdcb5bE2582A7B10ECF07B5A56c1bED524B7d90d0', ABI, provider.getSigner());
            setContract(tmpContract);
            const userAddress = accounts[0];
            const mintedCount = (await tmpContract.hasMinted(userAddress)).toNumber();
            setAlreadyMinted(mintedCount);
            //setAlreadyMinted((await tmpContract.hasMinted(accounts[0])).toNumber());
            setTotalSupply((await tmpContract.totalSupply()).toNumber());
            setConnected(true);

            window.ethereum.on("accountsChanged", () => {
                window.location.reload();
            });
            window.ethereum.on("chainChanged", () => {
                window.location.reload();
            });
        } else {
            setError('Please connect to Blast network.')
        }
    };

    const add = async () => {
        setMintAmount(Math.min(mintAmount + 1, 10 - (alreadyMinted)));
    }

    const sub = async () => {
        setMintAmount(Math.max(mintAmount - 1, 1));
    }

    return (
        <Section>
            {connected !== false ? (
                <div style={{ textAlign: "center" }}>
                    <div>
                        Connected with {account}
                    </div>
                    <div>
                        Progress: {totalSupply} / 10000
                    </div>
                    <div>
                        Nuggets Minted: {alreadyMinted} / 10
                    </div>
                    {alreadyMinted < 10 ? (<>
                        <div>
                            <Btn2 onClick={sub}>-</Btn2>
                            <Input value={mintAmount} readonly></Input>
                            <Btn2 onClick={add}>+</Btn2>
                        </div>
                        <div>
                            <Btn2 onClick={mint}>Mint!</Btn2>
                        </div>
                    </>) : (<>
                        <div>You received 10 NFTs, yay!</div>
                    </>)}
                </div>
            ) : (
                <div>
                    <Btn2 onClick={connect}>Connect Metamask</Btn2>
                </div>
            )}
            {error !== null ? (<div className={'error'} style={{ textAlign: "center" }}>{error}</div>) : (<></>)}
            <br />
            <br />
        </Section>
    );
};

export default Mint